
import { computed, defineComponent, ref } from "vue";

import { AppActionTypes, useStore } from "@/store";
import { loadUserDependentData, formatDate } from "@/helpers";
import { StudentModel } from "@/models";

import StudentFormVue from "@/components/StudentForm.vue";
import PageGreetingVue from "@/components/PageGreeting.vue";

import swal from "sweetalert";
import { Dialog } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";

export default defineComponent({
    components: {
        "k-dialog": Dialog,
        "student-form": StudentFormVue,
        "page-greeting": PageGreetingVue
    },
    data() {
        const store = useStore();
        const { getters, state } = store;

        // This is the current student being edited.
        const currentStudent = ref<StudentModel | null>(null);
        const students = computed(() => state.students);

        // Page greeting
        const title = "My Students 👨‍👩‍👦";
        const message = "Manage your students and their accounts.";

        const dialogTitle = computed(() =>
            currentStudent.value?.id && currentStudent.value.id > 0
                ? "Edit Student"
                : "Add Student"
        );

        loadUserDependentData(store, () => {
            if (students.value.length === 0) {
                store.dispatch(AppActionTypes.getStudents);
            }
        });

        const createStudent = (): void => {
            currentStudent.value = {
                id: 0,
                birthdate: null,
                name: "",
                firstName: "",
                password: "",
                confirmPassword: "",
                externalId: ""
            };
        };

        const editStudent = (student: StudentModel): void => {
            currentStudent.value = {
                id: student.id,
                birthdate: student.birthdate,
                name: student.name,
                firstName: student.firstName,
                externalId: student.externalId
            };
        };

        const refresh = (): void => {
            currentStudent.value = null;
        };

        const deleteStudent = async (student: StudentModel): Promise<void> => {
            const result = await swal({
                title: "Are you sure?",
                text: "Are you sure you want to delete this student?",
                icon: "warning",
                dangerMode: true,
                buttons: [true, true]
            });

            if (result) {
                // This calls the API here and the other functions do not since the delete button
                // is located on this page, whereas the others deffer to an editor form.
                await store.dispatch(AppActionTypes.removeStudent, student);
                refresh();
            }
        };

        return {
            user: computed(() => store.state.user),
            currentStudent,
            createStudent,
            editStudent,
            refresh,
            deleteStudent,
            formatDate,
            dialogTitle,
            students,
            loading: computed(() => getters.loading),
            title,
            message
        };
    }
});


import { StudentModel } from "@/models";
import { useStore } from "@/store";
import { Form, FormActions, useField } from "vee-validate";
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import {
    hasErrors,
    ValFormInput,
    getModelStateFromResponse,
    ValSummary
} from "@elite/validation";
import { AppActionTypes } from "@/store/actions";

export default defineComponent({
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "val-summary": ValSummary,
        "masked-text-box": MaskedTextBox
    },
    props: {
        student: {
            type: Object as PropType<StudentModel>,
            required: true
        }
    },
    emits: ["cancel", "saved"],
    setup(props, { emit }) {
        const model = reactive<StudentModel>(props.student);

        // Prevents non-alpha numeric characters from being entered in the username textbox
        const nameOnKeyDown = (e: KeyboardEvent): void => {
            if (!e.key) {
                return;
            }

            // This regex identifies NON-alphanumeric characters.
            // https://stackoverflow.com/questions/20864893/replace-all-non-alphanumeric-characters-new-lines-and-multiple-white-space-wit
            const regex = /[\W_]+/g;

            const match = e.key.match(regex);
            if (match && match.length >= 0) {
                e.preventDefault();
            }
        };

        const isNew = model.id === 0;

        const store = useStore();
        const rules = computed(() => {
            const studentModelRules =
                store.getters.getModelValidationRules("studentModel");
            // the generated model from swagger uses a string instead of a date so the fluent validation rules from the server do not include a date check
            studentModelRules.birthdate.date = true;
            return studentModelRules;
        });
        const errors = ref<string[]>([]);

        // Convert birthdate to MM/dd/yyyy format for textbox
        if (model.birthdate) {
            model.birthdate = model.birthdate.replace(
                /(\d{4})-(\d\d)-(\d\d).*/,
                "$2/$3/$1"
            );
        }

        // Initialize individual validation for birthdate so it can be attached to kendo masked input
        const {
            handleChange,
            handleBlur,
            meta: birthdateMeta,
            value: birthdateValue
        } = useField<string | undefined>(
            rules.value.birthdate.propertyName,
            rules.value.birthdate
        );

        // If there is birthday it populates from the model
        if (model.birthdate) {
            birthdateValue.value = model.birthdate;
        }

        // If the birthdate has no value we need to set it to empty string for validation to work correctly
        watch(birthdateValue, () => {
            if (birthdateValue.value === "__/__/____") {
                birthdateValue.value = undefined;
            }
        });

        const onSubmit = async (
            values: StudentModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            // Make sure birth date is valid
            if (!birthdateMeta.valid) {
                return;
            }

            errors.value = [];

            values.birthdate = birthdateValue.value
                ? birthdateValue.value.replace(
                      /(\d\d)\/(\d\d)\/(\d{4})/,
                      "$3-$1-$2"
                  )
                : null;

            const response = isNew
                ? await store.dispatch(AppActionTypes.createStudent, values)
                : await store.dispatch(AppActionTypes.updateStudent, values);

            if (hasErrors(response)) {
                const modelState = getModelStateFromResponse(response, actions);
                errors.value = modelState.modelErrors;
            } else {
                emit("saved");
            }
        };

        return {
            rules,
            model,
            onSubmit,
            errors,
            nameOnKeyDown,
            birthdateValue,
            handleChange,
            handleBlur,
            birthdateMeta,
            isNew
        };
    }
});
